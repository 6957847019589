import { memo, useEffect, useRef } from "react";
import { Controller, useFormContext } from 'react-hook-form';

import AutoNumeric from 'autonumeric';
import { FormControl, InputAdornment, InputLabel, OutlinedInput } from "@mui/material";
import { styled } from '@mui/system';

import { renderLabel } from "@ais/forms";
import { useRoomIdle } from '@ais/hooks';
import { Flex } from "@ais/palette";

const StyledInputAdornment = styled(InputAdornment)({
    marginTop: "24px",
    marginRight: "-5px"
});

const StyledOutlinedInput = styled(OutlinedInput)({
    alignItems: "flex-start"
});

const PercentageField = ({
    field: {
        id,
        label,
        tooltip,
        placeholder
    },
    disabled,
    clientExternalIcon,
    lockField,
    unlockField,
    onSubmit,
    onFocus,
    renderLink
}) => {
    const SHORT_ANSWER_MAX_CHARACTERS = 256;
    const { control, setValue } = useFormContext();
    const isIdle = useRoomIdle();
    const inputRef = useRef(null);
    const autoNumericRef = useRef(null);

    useEffect(() => {
        const inputElement = inputRef.current;
        const autoNumeric = new AutoNumeric(inputElement, {
            digitGroupSeparator: ',',
            decimalCharacter: '.',
            decimalPlaces: 2,
            suffixText: '%',
            modifyValueOnWheel: false,
            selectOnFocus: false,
            watchExternalChanges: true
        });

        autoNumericRef.current = autoNumeric;

        return () => {
            autoNumeric.remove();
        };
    }, []);

    const renderedLabel = renderLabel(label, tooltip, null, { fontSize: '20px' });

    const handleBlur = () => {
        if (isIdle) {
            setValue(id, answerList[id])
            return;
        }
        onSubmit(id);
        unlockField && unlockField()
    };

    const handleFocus = (e) => {
        onFocus(e, id)
        lockField && lockField()
    }

    return (
        <FormControl fullWidth>
            <InputLabel shrink variant="multiline-label">
                {renderedLabel}
            </InputLabel>
            <Controller
                key={id}
                control={control}
                name={id}
                render={({ field: { value, onChange } }) => (
                    <Flex direction="row" justify="space-between" align="center" style={{ width: '100%' }}>
                        <StyledOutlinedInput
                            notched
                            label={!!label ? renderLabel(label, tooltip) : null}
                            placeholder={placeholder}
                            inputProps={{
                                maxLength: SHORT_ANSWER_MAX_CHARACTERS,
                                "test-id": `ShortAnswer-Percentage-${id}`,
                                "test-value": value,
                                id: id
                            }}
                            endAdornment={
                                <StyledInputAdornment position="end">
                                    {clientExternalIcon}
                                </StyledInputAdornment>
                            }
                            sx={{
                                '& textarea': {
                                    whiteSpace: 'nowrap',
                                    resize: 'none',
                                    overflowX: 'hidden'
                                }
                            }}
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    e.preventDefault();
                                }
                            }}
                            value={value ? (value.toString().includes("%") ? value : Number(value) * 100) : ''}
                            onChange={onChange}
                            style={{ width: 'inherit' }}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                            inputRef={inputRef}
                            disabled={disabled}
                        />
                        {renderLink && renderLink()}
                    </Flex>
                )}
            />
        </FormControl>
    );
};

export default memo(PercentageField);