import { memo, useEffect, useRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import AutoNumeric from 'autonumeric';
import { FormControl, InputAdornment, InputLabel, OutlinedInput } from '@mui/material';
import { styled } from '@mui/system';

import { renderLabel } from '@ais/forms';
import { useRoomIdle } from '@ais/hooks';
import { useAppTypeProvider } from '@ais/providers';
import { Flex } from "@ais/palette";

import TbLink from '@ais/forms/src/V2/FieldComponents/TbLink/TbLink';
import { GetAnswerFromTrialBalanceSupportingValue, IsAnswerTrialBalance } from '@ais/forms/src/V2/FieldComponents/TbLink/TbLinkHelper';

const StyledInputAdornment = styled(InputAdornment)({
    marginTop: '24px',
    marginRight: '-5px'
});

const StyledOutlinedInput = styled(OutlinedInput)({
    alignItems: 'flex-start'
});

const NumberField = ({
    field: {
        id,
        label,
        tooltip,
        placeholder
    },
    disabled,
    clientExternalIcon,
    lockField,
    unlockField,
    onSubmit,
    onFocus,
    renderLink,
    project
}) => {

    const SHORT_ANSWER_MAX_CHARACTERS = 256;
    const { control } = useFormContext();
    const isIdle = useRoomIdle();
    const { isPortalApp } = useAppTypeProvider();

    const inputRef = useRef(null);
    const autoNumericRef = useRef(null);

    useEffect(() => {
        const inputElement = inputRef.current;
        const autoNumeric = new AutoNumeric(inputElement, {
            allowDecimalPadding: 'floats',
            decimalPlaces: 2,
            selectOnFocus: false,
            showWarnings: false,
            digitGroupSeparator: ',',
            modifyValueOnWheel: false,
            watchExternalChanges: true
        });

        autoNumericRef.current = autoNumeric;

        return () => {
            autoNumeric.remove();
        };
    }, []);

    const renderedLabel = renderLabel(label, tooltip, null, { fontSize: '20px' });

    const handleBlur = () => {
        if (isIdle) return;
        onSubmit(id);
        unlockField && unlockField();
    };

    const handleFocus = (e) => {
        onFocus(e, id);
        lockField && lockField();
    }

    return (
        <FormControl fullWidth>
            <InputLabel shrink variant="multiline-label">
                {renderedLabel}
            </InputLabel>
            <Controller
                key={id}
                control={control}
                name={id}
                render={({ field: { value, onChange } }) => (
                    <Flex direction="row" justify="space-between" align="center" style={{ width: '100%' }}>
                        <StyledOutlinedInput
                            notched
                            label={!!label ? renderLabel(label, tooltip) : null}
                            placeholder={placeholder}
                            inputProps={{
                                maxLength: SHORT_ANSWER_MAX_CHARACTERS,
                                'test-id': `ShortAnswer-Number-${id}`,
                                'test-value': GetAnswerFromTrialBalanceSupportingValue(value),
                                id: id
                            }}
                            endAdornment={
                                <StyledInputAdornment position="end">
                                    {isPortalApp &&
                                        <TbLink
                                            value={value}
                                            id={id}
                                            handleBlur={handleBlur}
                                            onChange={onChange}
                                            onSubmit={onSubmit}
                                            onFocus={onFocus}
                                            project={project}
                                            disabled={disabled}
                                            lockField={lockField}
                                            unlockField={unlockField}
                                            isIdle={isIdle}
                                        />}
                                    {clientExternalIcon}
                                </StyledInputAdornment>
                            }
                            sx={{
                                '& textarea': {
                                    whiteSpace: 'nowrap',
                                    resize: 'none',
                                    overflowX: 'hidden'
                                }
                            }}
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    e.preventDefault();
                                }
                            }}
                            value={GetAnswerFromTrialBalanceSupportingValue(value)}
                            onChange={onChange}
                            style={{ width: 'inherit' }}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                            inputRef={inputRef}
                            disabled={disabled}
                            readOnly={IsAnswerTrialBalance(value) && isPortalApp}
                        />
                        {renderLink && renderLink()}
                    </Flex>
                )}
            />
        </FormControl>
    );
};

export default memo(NumberField);