import React, { memo } from 'react';
import { FormControl, InputAdornment, InputLabel, OutlinedInput } from "@mui/material";
import { styled } from '@mui/system';
import { Controller, useFormContext } from 'react-hook-form';
import { Flex } from "@ais/palette";

import { renderLabel } from '@ais/forms';
import { useRoomIdle } from '@ais/hooks';

const StyledInputAdornment = styled(InputAdornment)({
  marginTop: "12px",
  marginRight: "-5px"
});

const StyledOutlinedInput = styled(OutlinedInput)({
  alignItems: "flex-start"
});

export const InstanceLongAnswer = (
  {
    field: {
      id,
      label,
      tooltip,
      placeholder
    },
    disabled,
    clientExternalIcon,
    lockField,
    unlockField,
    onSubmit,
    onFocus,
    renderLink
  }) => {
  const { control } = useFormContext();
  const renderedLabel = renderLabel(label, tooltip, null);
  const LONG_ANSWER_MAX_CHARACTERS = 4096;
  const isIdle = useRoomIdle();

  const handleBlur = () => {
    if (isIdle) return
    onSubmit(id);
    unlockField && unlockField()
  }
  const handleFocus = (e) => {
    onFocus(e, id)
    lockField && lockField()
  }

  return (
    <FormControl fullWidth>
      <InputLabel shrink variant="multiline-label">
        {renderedLabel}
      </InputLabel>
      <Controller
        key={id}
        control={control}
        name={id}
        render={({ field: { value, onChange } }) => (
          <Flex direction="row" justify="space-between" align="center" style={{ width: '100%' }}>
            <StyledOutlinedInput
              multiline
              notched
              minRows={1}
              maxRows={20}
              label={renderedLabel}
              placeholder={placeholder}
              inputProps={{
                maxLength: LONG_ANSWER_MAX_CHARACTERS,
                "test-id": `LongAnswer-${id}`,
                "test-value": value
              }}
              endAdornment={
                <StyledInputAdornment position="end">
                  {clientExternalIcon}
                </StyledInputAdornment>
              }
              value={value}
              onChange={onChange}
              onFocus={handleFocus}
              style={{ width: 'inherit' }}
              onBlur={handleBlur}
              disabled={disabled}
            />
            {renderLink && renderLink()}
          </Flex>
        )}
      />
    </FormControl>
  );
}

export default memo(InstanceLongAnswer);